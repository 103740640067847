import { FeatureCollection, feature, featureCollection, point, polygon } from '@turf/helpers';
import { bearing, booleanWithin, buffer, difference, distance, getCoords, lineIntersect, midpoint } from '@turf/turf';
import { Feature, Geometry, LineString, Point, Polygon, Position } from 'geojson';
import { CoreoGeometry, CoreoGeometryType } from '../types';

export interface MapboxMapStyle {
  id: string;
  styleId: string;
  search: RegExp;
  label: string;
  icon: string;
}

export const mapboxMapStyles: MapboxMapStyle[] = [{
  id: 'streets-v12',
  styleId: 'streets',
  label: 'Streets',
  search: /^streets\-v/,
  icon: '/assets/imgs/mapbox-streets-preview.png'
}, {
  id: 'satellite-v9',
  styleId: 'satellite',
  label: 'Satellite',
  search: /^satellite\-v/,
  icon: '/assets/imgs/mapbox-satellite-preview.jpeg'
}, {
  id: 'satellite-streets-v12',
  styleId: 'satellite-streets',
  label: 'Sat/Streets',
  search: /^satellite\-streets\-v/,
  icon: '/assets/imgs/mapbox-satellite-streets-preview.jpeg'
}, {
  id: 'outdoors-v12',
  styleId: 'outdoors',
  label: 'Outdoors',
  search: /^outdoors\-v/,
  icon: '/assets/imgs/mapbox-outdoors-preview.png'
}, {
  id: 'bing',
  styleId: 'bing',
  label: 'Bing',
  search: /^bing/,
  icon: '/assets/imgs/bing-maps-preview.png'
}];

export type MapboxMapStyleId = typeof mapboxMapStyles[number]['id'];

export const matchMapboxStyleId = (styleId: string): MapboxMapStyleId => {
  for (const m of mapboxMapStyles) {
    if (m.search.test(styleId)) {
      return m.id;
    }
  }
  return null;
}

export const mapboxMapStyleUrl = (id: MapboxMapStyleId): string => `mapbox://styles/mapbox/${id}?optimize=true`;
export const mapboxMapStyleById = (id: MapboxMapStyleId): MapboxMapStyle => mapboxMapStyles.find(a => a.id === id);

export const addPointToGeometry = (geometry: CoreoGeometry, point: Point, targetType: CoreoGeometryType, index?: number, ring: number = 0): CoreoGeometry => {
  if (!geometry || targetType === 'Point') {
    return point;
  }

  if (targetType === 'Polygon') {
    // Upgrade a point to a line
    if (geometry.type === 'Point') {
      return {
        type: 'LineString',
        coordinates: [
          geometry.coordinates,
          point.coordinates
        ]
      }
    } else if (geometry.type === 'LineString') {
      // Ensure we create a proper closed polygon
      return {
        type: 'Polygon',
        coordinates: [[
          geometry.coordinates[0],
          geometry.coordinates[1],
          point.coordinates,
          geometry.coordinates[0]
        ]]
      }
    } else {
      // Take a copy of the coordinates but pop off the last one (which is a repeat of the first)
      const coords = geometry.coordinates[ring].slice(0, geometry.coordinates[ring].length - 1);
      const insertIndex = index ?? geometry.coordinates[ring].length - 1;

      const coordinates: Position[] = [
        ...coords.slice(0, insertIndex),
        point.coordinates,
        ...coords.slice(insertIndex)
      ];

      return {
        type: 'Polygon',
        coordinates: [
          ...geometry.coordinates.slice(0, ring),
          [
            ...coordinates,
            coordinates[0]
          ],
          ...geometry.coordinates.slice(ring + 1)
        ]
      };
    }
  }

  // targetType = Line String
  if (geometry.type === 'Point') {
    return {
      type: 'LineString',
      coordinates: [
        geometry.coordinates,
        point.coordinates
      ]
    };
  } else if (geometry.type === 'LineString') {
    const insertIndex = index ?? geometry.coordinates.length;
    return {
      type: 'LineString',
      coordinates: [
        ...geometry.coordinates.slice(0, insertIndex),
        point.coordinates,
        ...geometry.coordinates.slice(insertIndex)
      ]
    };
  }
};

const arrayRemove = <T = any>(array: T[], index: number): T[] => (
  [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ]
);

export const removePointFromGeometry = (geometry: CoreoGeometry, index: number, ring: number = 0): CoreoGeometry => {
  if (!geometry || geometry.type === 'Point') {
    return null;
  }

  if (geometry.type === 'LineString') {
    // Either convert it to a point, or a shorter line
    const coordinates = arrayRemove(geometry.coordinates, index ?? geometry.coordinates.length - 1);

    return geometry.coordinates.length === 2 ? {
      'type': 'Point',
      coordinates: coordinates[0]
    } : {
      type: 'LineString',
      coordinates
    };
  }
  // Polygon
  // 4 is the minimum number of points (as the last point is a repeat of the first)
  // If we are editing the outer ring, just collapse into a LineString
  // Else remove the ring entirely
  if (geometry.coordinates[ring].length === 4) {
    if (ring === 0) {
      return {
        type: 'LineString',
        // Pop off the last (repeated) polygon coordinate, then remove the required index
        coordinates: arrayRemove(geometry.coordinates[ring].slice(0, -1), index ?? geometry.coordinates.length - 1)
      };
    }
    return {
      type: 'Polygon',
      coordinates: [
        ...geometry.coordinates.slice(0, ring),
        ...geometry.coordinates.slice(ring + 1)
      ]
    };
  }

  // This takes the final point off, then removes that index
  const basePolygonCoordinates = geometry.coordinates[ring].slice(0, -1);
  const polygonCoordinates = arrayRemove(basePolygonCoordinates, index ?? basePolygonCoordinates.length - 1);

  return {
    type: 'Polygon',
    coordinates: [
      ...geometry.coordinates.slice(0, ring),
      [
        ...polygonCoordinates,
        polygonCoordinates[0]
      ],
      ...geometry.coordinates.slice(ring + 1)
    ]
  };
}

export const geometryLength = (geometry: CoreoGeometry): number => {
  if (!geometry) {
    return 0;
  } else if (geometry.type === 'Point') {
    return 1;
  } else if (geometry.type === 'LineString') {
    return geometry.coordinates.length;
  } else {
    return geometry.coordinates[0].length - 1;
  }
}

const numberFormat = new Intl.NumberFormat('en', {
  maximumFractionDigits: 2
});

export const geometryMetrics = (geometry: CoreoGeometry): FeatureCollection<Point> => {
  const midpoints: FeatureCollection<Point> = emptyFeatureCollection<Point>();

  if (!geometry || geometry.type === 'Point') {
    return midpoints;
  }

  let index = 0;

  const buildBearing = (a: Position, b: Position) => {
    const base = bearing(a, b);
    let output = base + 270;
    if (base > 90 && base < 180) {
      output = base - 90;
    } else if (base < 0 && base > -180) {
      output = base + 90;
    }
    return output;
  };

  const buildPoint = (a: Position, b: Position) => ({
    ...midpoint(a, b),
    id: index++,
    properties: {
      distance: numberFormat.format(distance(a, b, {
        units: 'meters'
      })),
      bearing: buildBearing(a, b)
    }
  });

  const rings = geometry.type === 'LineString' ? [geometry.coordinates] : geometry.coordinates;

  for (let ring = 0; ring < rings.length; ring++) {
    for (let i = 0; i < rings[ring].length - 1; i++) {
      midpoints.features.push(buildPoint(rings[ring][i], rings[ring][i + 1]));
    }
  }
  return midpoints;
}

export const featureToPoints = (f: Feature<CoreoGeometry>): FeatureCollection<Point> => {
  const points: FeatureCollection<Point> = emptyFeatureCollection<Point>();
  if (!f?.geometry) {
    return points;
  }
  const seed = 0;
  const { geometry } = f;

  if (geometry.type === 'Point') {
    points.features.push({
      id: seed,
      type: 'Feature',
      properties: {
        index: 0
      },
      geometry
    });
    return points;
  }

  const coordinates: Position[] = [] = geometry.type === 'LineString' ? geometry.coordinates : geometry.coordinates[0].slice(0, -1);

  for (let i = 0; i < coordinates.length; i++) {
    points.features.push({
      id: seed + i,
      type: 'Feature',
      properties: {
        index: i
      },
      geometry: {
        type: 'Point',
        coordinates: coordinates[i]
      }
    });
  }
  return points;
}

export const emptyFeatureCollection = <T extends Geometry, P = { [name: string]: any }>(): FeatureCollection<T, P> => ({
  type: 'FeatureCollection',
  features: []
});

export const moveGeometry = (geometry: CoreoGeometry, delta: [number, number]): CoreoGeometry => {

  const move = (p: Position) => ([p[0] + delta[0], p[1] + delta[1]]);

  switch (geometry.type) {
    case 'Polygon': {
      return {
        type: 'Polygon' as const,
        coordinates: geometry.coordinates.map(ring => ring.map(p => move(p)))
        // coordinates: [
        //   geometry.coordinates[0].map(p => move(p))
        // ]
      };
    }
    case 'LineString': {
      return {
        type: 'LineString',
        coordinates: geometry.coordinates.map(p => move(p))
      };
    }
    case 'Point': {
      return {
        type: 'Point',
        coordinates: move(geometry.coordinates)
      };
    }
    default: {
      return geometry;
    }
  }
}

export const splitPolygon = (poly: Polygon, line: LineString): FeatureCollection<Polygon> => {

  // Check that we have an intersection
  const intersection = lineIntersect(line, poly);
  if (intersection.features.length === 0) {
    return featureCollection<Polygon>([feature(poly)]);
  }

  // Check if the line starts or ends inside the polygon
  const lineCoords = getCoords(line);
  const startPoint = point(lineCoords[0]);
  const endPoint = point(lineCoords[lineCoords.length - 1]);
  const polygonFeature = polygon(poly.coordinates);

  if (booleanWithin(startPoint, polygonFeature) || booleanWithin(endPoint, polygonFeature)) {
    return featureCollection<Polygon>([feature(poly)]);
  }

  const splitter = buffer(line, 0.0001, { units: 'meters' });
  const body = difference(poly, splitter);
  const result: Feature<Polygon>[] = [];

  if (body.geometry.type === 'Polygon') {
    result.push(polygon(body.geometry.coordinates));
  } else {
    result.push(...body.geometry.coordinates.map(c => polygon(c)));
  }

  return {
    type: 'FeatureCollection',
    features: result
  };
}
