import { createSelector } from 'reselect';

import { FormState, FormsState } from './forms.reducer';

export const getFormsForms = (state: FormsState): FormState[] => state.forms;
export const getFormsCurrentForm = (state: FormsState): FormState => state.forms[state.forms.length - 1];
export const getFormsCurrentFormId = createSelector(getFormsCurrentForm, form => form?.id);
export const getFormsForm = uuid => (state: FormsState): FormState => state.forms.find(f => f.uuid === uuid);
export const getFormsFormSections = uuid => createSelector(getFormsForm(uuid), form => form.sections);
export const getFormsFormBlocks = uuid => createSelector(getFormsFormSections(uuid), sections => sections.reduce((acc, section) => acc.concat(section.blocks), []));
export const getFormsFormRecord = uuid => createSelector(getFormsForm(uuid), form => form?.record ?? null);
export const getFormsFormDirty = uuid => createSelector(getFormsForm(uuid), form => form?.dirty);
export const getFormsFormReady = uuid => createSelector(getFormsForm(uuid), form => form?.ready);
export const getFormsFormSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.sectionId);
export const getFormsFormNextSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.nextSectionId);
export const getFormsFormPrevSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.prevSectionId);
