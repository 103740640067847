import validate from 'validate.js';

import { CoreoAttribute, CoreoRecord } from '../types';

export class ValidationEngine {
  static buildConstraints = (attribute: CoreoAttribute): any => {
    const cfg = attribute.config || {};
    const constraints: any = {};

    constraints.presence = attribute.required ? {
      allowEmpty: false
    } : false;

    switch (attribute.questionType) {
      case 'child':
      case 'mediaGallery':
      case 'association': {
        constraints.length = {
          minimum: cfg.min || 0,
          maximum: cfg.max || Number.MAX_SAFE_INTEGER,
          tooShort: 'must have at least %{count}',
          tooLong: 'may have up to %{count}'
        };
        break;
      }
    }

    switch (attribute.type) {
      case 'integer': {
        const numericalityConfig: any = {
          onlyInteger: true,
        };
        if (cfg.hasOwnProperty('min') && !isNaN(parseInt(cfg.min, 10))) {
          numericalityConfig.greaterThanOrEqualTo = Number(cfg.min);
        }
        if (cfg.hasOwnProperty('max') && !isNaN(parseInt(cfg.max, 10))) {
          numericalityConfig.lessThanOrEqualTo = Number(cfg.max);
        }
        constraints.numericality = numericalityConfig;
        break;
      }
      case 'float': {
        const numericalityConfig: any = {
          onlyInteger: false,
        };
        if (cfg.hasOwnProperty('min') && !isNaN(parseInt(cfg.min, 10))) {
          numericalityConfig.greaterThanOrEqualTo = Number(cfg.min);
        }
        if (cfg.hasOwnProperty('max') && !isNaN(parseInt(cfg.max, 10))) {
          numericalityConfig.lessThanOrEqualTo = Number(cfg.max);
        }
        constraints.numericality = numericalityConfig;
        break;
      }
      case 'boolean': {
        if (attribute.questionType === 'confirm' && attribute.required) {
          constraints.inclusion = {
            within: [true],
            message: 'must be confirmed to proceed'
          };
          break;
        }
        constraints.inclusion = {
          within: [true, false, 1, 0, 'true', 'false', '1', '0'],
          message: 'must be true, false, 1, 0, "true", "false", "0" or "1"'
        };
        break;
      }
      case 'text': {
        if (cfg.format) {
          constraints.format = {
            pattern: cfg.format,
            flags: 'i'
          };
        }
        break;
      }
      case 'email':
      case 'url': {
        constraints[attribute.type] = true;
        break;
      }
    }
    return constraints;
  };

  static validateAnswer = (attribute: CoreoAttribute, constraints: any, record: CoreoRecord): string => {
    // let constraints: any = buildConstraints(attribute);

    // Determine what value to pass to the constraint based on the attribute type
    let value;

    switch (attribute.questionType) {
      case 'geometry': {
        value = record.geometry;
        break;
      }
      case 'child':
      case 'association': {
        value = record.associates[attribute.id] || [];
        break;
      }
      case 'signature':
      case 'photo': {
        value = record.attachments.find(a => a.attributeId === attribute.id);
        break;
      }
      case 'mediaGallery': {
        value = record.attachments.filter(a => a.attributeId === attribute.id);
        break;
      }
      default: {
        value = record && attribute && attribute.path && record.data[attribute.path];
      }
    }

    const result = validate({ value }, { value: constraints });
    return result && result.value && result.value[0] || null;
  };
}

export default ValidationEngine;
