import { capSQLiteSet } from "@capacitor-community/sqlite";
import { Feature } from "geojson";
import { CoreoCollectionItem } from "../../../types";
import { SyncItem } from "../app-sync.service";
import SQLDatabase, { SQLQueryBuilder } from "../sql.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseItem {
  id: number;
  key: string;
  value: string;
  data: string;
  projectId: number;
  collectionId: number;
  createdAt: string;
  updatedAt: string;
  geometry: string;
  sort: number;
  timestamp: number;
}


export class AppDatabaseItemRepo extends AppDatabaseRepo<AppDatabaseItem, SyncItem, CoreoCollectionItem> {
  tableName: string = 'items';

  public toInsertSet(item: SyncItem): capSQLiteSet[] {
    const statement = `INSERT OR REPLACE INTO items
      (id,key,value,data,projectId,collectionId,createdAt,updatedAt,geometry,sort,timestamp)
      VALUES
      (?,?,?,?,?,?,?,?,?,?,?);`;
    return [{
      statement,
      values: [
        item.id,
        item.key,
        item.value,
        JSON.stringify(item.data),
        item.projectId,
        item.collectionId,
        item.createdAt,
        item.updatedAt,
        JSON.stringify(item.geometry),
        item.sort,
        item.timestamp
      ]
    }];
  }

  async deserialise(entity: AppDatabaseItem): Promise<CoreoCollectionItem> {
    return {
      ...entity,
      data: entity.data && JSON.parse(entity.data),
      geometry: entity.geometry && JSON.parse(entity.geometry)
    };
  }

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['key', 'TEXT NOT NULL'],
    ['value', 'TEXT NOT NULL'],
    ['data', 'TEXT NOT NULL'],
    ['collectionId', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['createdAt', 'TEXT NOT NULL'],
    ['updatedAt', 'TEXT NOT NULL'],
    ['timestamp', 'INTEGER NOT NULL'],
    ['geometry', 'TEXT'],
    ['sort', 'INTEGER'],
  ];

  indexes = [
    'CREATE INDEX IF NOT EXISTS items_projectId ON items(projectId)',
    'CREATE INDEX IF NOT EXISTS items_collectionId ON items(collectionId)'
  ];
  references = [];
  primaryKey = ["id", "projectId", "collectionId"];


  getByCollectionId(collectionId: number, projectId: number, batchSize?: number): Promise<CoreoCollectionItem[]> {
    return this.search(q => q.where('collectionId = ?', collectionId).where('projectId = ?', projectId), batchSize).toArray();
  }

  private deserializeFeature(item: AppDatabaseItem): Feature {
    return {
      type: 'Feature',
      geometry: JSON.parse(item.geometry),
      id: item.id,
      properties: {
        id: item.id,
        collectionId: item.collectionId
      }
    };
  }

  async findProjectMapFeatures(queryBuilder: SQLQueryBuilder): Promise<Feature[]> {

    const query = queryBuilder(SQLDatabase.select().from(this.tableName)
      .field('id')
      .field('collectionId')
      .field('geometry')
      .where('geometry IS NOT NULL'));

    try {
      const features = [];
      // We query the DB directly so we don't deserialise the records first
      for await (const batch of SQLDatabase.instance.queryAll(query, 1000)) {
        features.push(...batch.map(mf => this.deserializeFeature(mf as any)));
      }

      return features;
    } catch (e) {
      console.warn(e);
      return [];
    }
  }
}
