import { createSelector } from 'reselect';

import { CoreoUser } from '../../types';

import { AuthState } from './auth.reducer';

export const getAuthIsAuth = (state: AuthState): boolean => state.isAuth;
export const getAuthError = (state: AuthState): any => state.authError;
export const getAuthInProgress = (state: AuthState): boolean => state.authInProgress;
export const getAuthVerifyError = (state: AuthState): any => state.verifyError;
export const getAuthVerifyInProgress = (state: AuthState): boolean => state.verifyInProgress;
export const getAuthToken = (state: AuthState): string => state.token;
export const getAuthUser = (state: AuthState): CoreoUser => state.user;
export const getAuthUserId = createSelector(getAuthUser, user => user?.userId);
export const getAuthUserImageUrl = createSelector(getAuthUser, user => user && user.imageUrl);
export const getAuthSignupProjectId = (state: AuthState): number => state.signupProjectId;
