import { CoreoRecord, CoreoRecordCustomFilter, CoreoRecordFilter } from '../../types';
import { TypeKeys } from '../actions';
import { RecordsActions } from './records.actions';

export interface RecordsState {
  record: CoreoRecord;
  recordFilter: CoreoRecordFilter;
  recordsLoading: boolean;
  recordsMapLoading: boolean;
}

const initialState: RecordsState = {
  record: null,
  recordFilter: {
    userId: null,
    states: [],
    from: null,
    to: null,
    forms: [],
    customFilters: [],
    customOperator: 'and'
  },
  recordsLoading: true,
  recordsMapLoading: true
};

const recordsReducer = (state = initialState, action: RecordsActions): RecordsState => {
  switch (action.type) {
    // case TypeKeys.RECORDS_LOAD_RECORD_SUCCESS: {
    //   const { record } = action;

    //   const existing = state.records.find(r => r.id === record.id);

    //   const records = !existing
    //     ? [...state.records, record]
    //     : state.records.map(r => r.id === record.id ? record : r);

    //   return {
    //     ...state,
    //     records,
    //     record

    //   };
    // }

    case TypeKeys.RECORDS_UPDATE_FILTER: {
      const { filter } = action;
      return {
        ...state,
        recordFilter: {
          ...state.recordFilter,
          ...filter
        }
      };
    }

    case TypeKeys.RECORDS_CLEAR_FILTER: {
      return {
        ...state,
        recordFilter: {
          userId: null,
          states: [],
          from: null,
          to: null,
          forms: [],
          customFilters: [],
          customOperator: 'and'
        }
      };
    }

    case TypeKeys.RECORDS_ADD_CUSTOM_FILTER: {
      const newCustomFilter: CoreoRecordCustomFilter = {
        id: Math.random().toString(36).substring(2),
        formId: null,
        attribute: null,
        operator: null,
        value: null,
      }
      return {
        ...state,
        recordFilter: {
          ...state.recordFilter,
          customFilters: [...state.recordFilter.customFilters, newCustomFilter]
        }
      };
    }

    case TypeKeys.RECORDS_UPDATE_CUSTOM_FILTER: {
      const { customFilter } = action;

      const filterIdx = state.recordFilter.customFilters.findIndex(c => c.id === customFilter.id);

      return {
        ...state,
        recordFilter: {
          ...state.recordFilter,
          customFilters: [
            ...state.recordFilter.customFilters.slice(0, filterIdx),
            customFilter,
            ...state.recordFilter.customFilters.slice(filterIdx + 1)
          ]
        }
      };
    }

    case TypeKeys.RECORDS_DELETE_CUSTOM_FILTER: {
      const { id } = action;

      const filterIdx = state.recordFilter.customFilters.findIndex(c => c.id === id);

      return {
        ...state,
        recordFilter: {
          ...state.recordFilter,
          customFilters: [
            ...state.recordFilter.customFilters.slice(0, filterIdx),
            ...state.recordFilter.customFilters.slice(filterIdx + 1)
          ]
        }
      };
    }

    case TypeKeys.RECORDS_CLEAR: {
      return {
        ...state,
        // records: [],
        // recordsCount: 0,
        recordsLoading: false
      };
    }

    case TypeKeys.RECORDS_LOAD_MORE: {
      return {
        ...state,
        recordsLoading: true
      }
    }

    // case TypeKeys.RECORDS_LOAD_MORE_SUCCESS: {
    //   const { records } = action;

    //   return {
    //     ...state,
    //     // records: [...state.records, ...records],
    //     recordsLoading: false
    //   };
    // }

    case TypeKeys.RECORDS_LOAD_MORE_FAILURE: {
      return {
        ...state,
        recordsLoading: false
      }
    }


    // case TypeKeys.RECORDS_SAVE_PENDING: {
    //   const { id, pending } = action;
    //   const idx = state.apps
    //   return {
    //     ...state,
    //     apps
    //   };
    //   // const idx = state.pending.findIndex(p => p.id === action.pending.id);
    //   // let pending: CoreoPendingRecord[];

    //   // if (idx === -1) {
    //   //   pending = [
    //   //     ...state.pending,
    //   //     action.pending
    //   //   ];
    //   // } else {
    //   //   pending = [
    //   //     ...state.pending.slice(0, idx),
    //   //     {
    //   //       ...action.pending,
    //   //     },
    //   //     ...state.pending.slice(idx + 1)
    //   //   ];
    //   // }
    //   // return {
    //   //   ...state,
    //   //   pending
    //   // };
    // }

    // case TypeKeys.RECORDS_SUBMIT: {
    //   const { id } = action;
    //   const idx = state.pending.findIndex(p => p.id === id);
    //   return {
    //     ...state,
    //     pending: [
    //       ...state.pending.slice(0, idx),
    //       {
    //         ...state.pending[idx],
    //         state: CoreoPendingRecordState.UPLOADING
    //       },
    //       ...state.pending.slice(idx + 1)
    //     ]
    //   };
    // }

    // Discarding and successful submit both have the same effect
    // of removing the record from the list
    // case TypeKeys.RECORDS_DISCARD_PENDING:
    // case TypeKeys.RECORDS_SUBMIT_SUCCESS: {
    //   const { id } = action;
    //   const idx = state.pending.findIndex(p => p.id === id);
    //   return {
    //     ...state,
    //     pending: [
    //       ...state.pending.slice(0, idx),
    //       ...state.pending.slice(idx + 1)
    //     ]
    //   };
    // }

    // case TypeKeys.RECORDS_SUBMIT_FAILURE: {
    //   const { id, pendingState } = action;
    //   const idx = state.pending.findIndex(p => p.id === id);
    //   return {
    //     ...state,
    //     pending: [
    //       ...state.pending.slice(0, idx),
    //       {
    //         ...state.pending[idx],
    //         state: pendingState
    //       },
    //       ...state.pending.slice(idx + 1)
    //     ]
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default recordsReducer;
