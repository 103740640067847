import { CoreoUser } from '../../types';
import { TypeKeys } from '../actions';

export interface AuthState {
  token: string;
  user: CoreoUser;
  isAuth: boolean;
  authInProgress: boolean;
  authError: string;
  verifyInProgress: boolean;
  verifyError: boolean;
  logoutInProgress: boolean;
  logoutError: string;
  signupProjectId: number;
};

const initialState: AuthState = {
  token: null,
  user: null,
  isAuth: false,
  authInProgress: false,
  authError: null,
  verifyInProgress: false,
  verifyError: null,
  logoutInProgress: false,
  logoutError: null,
  signupProjectId: null
};

const authReducer = (state = initialState, action): AuthState => {
  switch (action.type) {
    case TypeKeys.AUTH_START: {
      return {
        ...state,
        authInProgress: true,
        authError: null
      };
    }

    case TypeKeys.AUTH_RESTART: {
      return {
        ...state,
        authInProgress: false,
        authError: null
      };
    }

    case TypeKeys.AUTH_SUCCESS: {
      const { token, user } = action;
      return {
        ...state,
        isAuth: true,
        authInProgress: false,
        token,
        user
      };
    }

    case TypeKeys.AUTH_FAIL: {
      const { error } = action;
      return {
        ...initialState,
        signupProjectId: state.signupProjectId,
        authError: error
      };
    }

    case TypeKeys.AUTH_VERIFY_START: {
      return {
        ...state,
        verifyInProgress: true,
        verifyError: null
      };
    }

    case TypeKeys.AUTH_VERIFY_SUCCESS: {
      const { token, user } = action;
      return {
        ...state,
        isAuth: true,
        verifyInProgress: false,
        token,
        user
      };
    }

    case TypeKeys.AUTH_VERIFY_FAIL: {
      const { error } = action;
      return {
        ...initialState,
        signupProjectId: state.signupProjectId,
        verifyError: error
      };
    }

    case TypeKeys.AUTH_UPDATE_USER_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        user
      }
    }

    case TypeKeys.AUTH_LOGOUT: {
      return initialState;
    }

    case TypeKeys.AUTH_SET_SIGNUP_PROJECT_ID: {
      return {
        ...state,
        signupProjectId: action.projectId
      };
    }

    default: {
      return state;
    }
  }
}

export default authReducer;
