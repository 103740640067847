import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoForm } from "../../../types";
import { SyncForm } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseForm {
  id: number;
  projectId: number;
  name: string;
  title: string;
  slug: string;
  anonymous: boolean;
  thankyou: string;
  isPrimary: boolean;
  titleAttributeId: number;
  secondaryTitleAttributeId: number;
  color: string;
  visible: boolean;
  allowMemberUpdate: boolean;
  private: boolean;
  sort: number;
  style: string;
  mapSort: number;
  mapVisible: boolean;
}

export class AppDatabaseFormRepo extends AppDatabaseRepo<AppDatabaseForm, SyncForm, CoreoForm> {

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['title', 'TEXT'],
    ['slug', 'TEXT'],
    ['anonymous', 'INTEGER'],
    ['thankyou', 'TEXT'],
    ['isPrimary', 'INTEGER'],
    ['titleAttributeId', 'INTEGER'],
    ['secondaryTitleAttributeId', 'INTEGER'],
    ['color', 'TEXT'],
    ['visible', 'INTEGER'],
    ['allowMemberUpdate', 'INTEGER'],
    ['private', 'INTEGER'],
    ['sort', 'INTEGER'],
    ['style', 'TEXT'],
    ['mapSort', 'INTEGER'],
    ['mapVisible', 'INTEGER']
  ];
  indexes = [];
  references = [];

  primaryKey = ["id", "projectId"];
  tableName = 'forms';

  public toInsertSet(form: SyncForm): capSQLiteSet[] {
    return [{
      statement: `INSERT OR REPLACE INTO ${this.tableName} (id,projectId,name,title,slug,anonymous,thankyou,isPrimary,titleAttributeId,secondaryTitleAttributeId,color,visible,allowMemberUpdate,private,sort,style,mapSort,mapVisible) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
      values: [
        form.id,
        form.projectId,
        form.name,
        form.title,
        form.slug,
        form.anonymous,
        form.thankyou,
        form.isPrimary,
        form.titleAttributeId,
        form.secondaryTitleAttributeId,
        form.color,
        form.visible,
        form.allowMemberUpdate,
        form.private,
        form.sort,
        JSON.stringify(form.style),
        form.mapSort,
        form.mapVisible
      ]
    }];
  }


  async deserialise(form: AppDatabaseForm): Promise<CoreoForm> {
    return {
      ...form,
      style: JSON.parse(form.style)
    };
  }

}
