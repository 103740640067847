import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Dispatch, Middleware } from 'redux';

import { Actions, TypeKeys } from './actions';
import { ApplicationState } from './reducers';

export const analyticsMiddleware: Middleware<{}, ApplicationState, Dispatch<Actions>> = _store => next => (action: Actions) => {

  switch (action.type) {
    case TypeKeys.APP_LOAD_SUCCESS: {
      FirebaseAnalytics.setUserProperty({
        key: 'appId',
        value: action.app.id.toString()
      });
      break;
    }
  }
  return next(action);
}
