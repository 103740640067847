import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoAttribute, CoreoAttributeQuestionType, CoreoAttributeType } from "../../../types";
import { SyncAttribute } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseAttribute {
  id: number;
  projectId: number;
  uuid: string;
  order: number;
  visible: boolean;
  label: string;
  path: string;
  type: CoreoAttributeType;
  required: boolean;
  filterable: boolean;
  collectionId: number;
  parentCollectionId: number;
  associatedSurveyId: number;
  surveyId: number;
  meta: string;
  questionType: CoreoAttributeQuestionType;
  sectionIdx: number;
  config: string;
  conditions: string;
  text: string;
  description: string;
  help: string;
}

export class AppDatabaseAttributeRepo extends AppDatabaseRepo<AppDatabaseAttribute, SyncAttribute, CoreoAttribute> {
  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['uuid', 'TEXT'],
    ['order', 'INTEGER'],
    ['visible', 'INTEGER'],
    ['label', 'TEXT'],
    ['path', 'TEXT'],
    ['type', 'TEXT'],
    ['required', 'INTEGER'],
    ['filterable', 'INTEGER'],
    ['collectionId', 'INTEGER'],
    ['parentCollectionId', 'INTEGER'],
    ['associatedSurveyId', 'INTEGER'],
    ['surveyId', 'INTEGER'],
    ['meta', 'TEXT'],
    ['questionType', 'TEXT'],
    ['sectionIdx', 'INTEGER'],
    ['config', 'TEXT'],
    ['conditions', 'TEXT'],
    ['text', 'TEXT'],
    ['description', 'TEXT'],
    ['help', 'TEXT'],
  ];
  references = [];
  indexes = [];
  tableName = 'attributes';
  primaryKey = ['id', 'projectId'];


  public toInsertSet(attribute: SyncAttribute): capSQLiteSet[] {
    return [{
      statement: 'INSERT OR REPLACE INTO attributes (id,projectId,uuid,"order",visible,label,path,type,required,filterable,collectionId,parentCollectionId,associatedSurveyId,surveyId,meta,questionType,sectionIdx,config,conditions,text,description,help) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
      values: [
        attribute.id,
        attribute.projectId,
        attribute.uuid,
        attribute.order,
        attribute.visible,
        attribute.label,
        attribute.path,
        attribute.type,
        attribute.required,
        attribute.filterable,
        attribute.collectionId,
        attribute.parentCollectionId,
        attribute.associatedSurveyId,
        attribute.surveyId,
        JSON.stringify(attribute.meta),
        attribute.questionType,
        attribute.sectionIdx,
        JSON.stringify(attribute.config),
        JSON.stringify(attribute.conditions),
        attribute.text,
        attribute.description,
        attribute.help
      ]
    }];
  }
  async deserialise(a: AppDatabaseAttribute): Promise<CoreoAttribute> {
    return {
      ...a,
      conditions: JSON.parse(a.conditions),
      config: JSON.parse(a.config),
      meta: JSON.parse(a.meta),
      visible: !!a.visible,
      required: !!a.required,
      filterable: !!a.filterable
    };
  }
}
