import { MapboxMapStyleId, mapboxMapStyles } from '../../services/geometry.service';
import { OfflineMap } from '../../types/offlineMaps.types';
import { TypeKeys } from '../actions';
import { AppUninstallAction } from '../app/app.actions';

import { MapActions } from './map.actions';

export interface MapLayerState {
  id: string;
  layerType: 'records' | 'collection' | 'custom';
  sourceId: number;
  enabled: boolean;
  locked?: boolean;
  hidden?: boolean;
}

export interface MapBaseState {
  baseStyleId: MapboxMapStyleId | OfflineMap['uuid'];
  showAppBounds: boolean;
  showScaleBar: boolean;
}

export interface MapInstanceState extends MapBaseState {
  projectId: number;
  id: string;
  layers: MapLayerState[];
  dataLayers: MapLayerState[];
}

interface MapInstanceStateStore {
  [key: string]: {
    layers: MapLayerState[];
    dataLayers: MapLayerState[];
    projectId: number;
  }
};
export interface MapState extends MapBaseState {
  maps: MapInstanceStateStore;
};

export const initialState: MapState = {
  baseStyleId: mapboxMapStyles[0].id,
  showAppBounds: false,
  showScaleBar: false,
  maps: {}
};

const mapReducer = (state = initialState, action: MapActions | AppUninstallAction): MapState => {
  switch (action.type) {

    case TypeKeys.MAP_UPDATE_STATE: {
      const { state: instanceState } = action;
      const { id, baseStyleId, showAppBounds, showScaleBar, layers, dataLayers } = instanceState;

      // We are updating a non-persisted map - so just update global settings
      if (!id) {
        return {
          ...state,
          baseStyleId,
          showAppBounds,
          showScaleBar
        };
      }

      return {
        baseStyleId,
        showAppBounds,
        showScaleBar,
        maps: {
          ...state.maps,
          [id]: {
            layers,
            dataLayers,
            projectId: instanceState.projectId
          }
        }
      };
    }

    case TypeKeys.APP_UNINSTALL: {
      const { id } = action;

      const maps: MapInstanceStateStore = {};

      for (const mapId in state.maps) {
        if (state.maps[mapId].projectId !== id) {
          maps[mapId] = state.maps[mapId];
        }
      }

      return {
        ...state,
        maps
      }
    }

    default: {
      return state;
    }
  }
};

export default mapReducer;
