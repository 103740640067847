import { ConnectionType } from '@capacitor/network';
import bbox from '@turf/bbox';
import { createSelector } from 'reselect';

import { CONFIGS } from '../../config';
import { displayValue } from '../../services/attribute-utils';
import FilteringEngine from '../../services/filtering.service';
import { CoreoApp, CoreoAttribute, CoreoProjectFeatures, CoreoRecord } from '../../types';

import { booleanWithin, polygon } from '@turf/turf';
import { AppInstallStatus, AppSettings, AppState, AppStatus, MediaDownloadStatus } from './app.reducer';

export const filterAttributesForForm = (id: number, attributes: CoreoAttribute[]) =>
  attributes
    .filter(a => (a.surveyId === id) && (a.questionType !== null))
    .sort((a, b) => a.order - b.order);

const postProcessedTypes = [
  'rgeolocation',
  'coordinatetransform'
];
export const filterAttributesForDisplay = (id: number, attributes: CoreoAttribute[]) =>
  attributes
    .filter(a => a.surveyId === id)
    .filter(a => {
      return (postProcessedTypes.includes(a.type)) || a.questionType !== null
    })
    .sort((a, b) => a.order - b.order);

export const getAppReady = (state: AppState): boolean => state.ready;

export const getApp = (state: AppState): CoreoApp => state.app;
export const getAppId = (state: AppState): number => state.appId;

export const getAppEnvironment = (state: AppState) => state.environment ?? 'prod';
export const getAppConfig = createSelector(getAppEnvironment, environment => CONFIGS[environment]);
export const getAppConfigAdminUrl = createSelector(getAppConfig, config => config.COREO_ADMIN_URL);
export const getAppConfigEnvironmentName = createSelector(getAppConfig, config => config.NAME);
export const getAppConfigMapboxToken = createSelector(getAppConfig, config => config.MAPBOX_TOKEN);
export const getAppConfigMapsUrl = createSelector(getAppConfig, config => config.COREO_MAPS_URL);
export const getAppConfigApiUrl = createSelector(getAppConfig, config => config.COREO_API_URL);
export const getAppConfigSentryDsn = createSelector(getAppConfig, config => config.SENTRY_DSN);
export const getLocalDatabaseName = createSelector(getAppConfig, config => config.LOCAL_DATABASE_NAME);
export const getAppConfigBingToken = createSelector(getAppConfig, config => config.BING_TOKEN);

export const getAppIsDev = createSelector(getAppConfig, config => config.IS_DEV);

export const getAppSettings = (state: AppState): AppSettings => state.settings;
export const getAppNetworkConnectionType = (state: AppState): ConnectionType => state.networkConnectionType;
export const getAppNetworkConnected = (state: AppState): boolean => state.networkConnected;
export const getAppProjectId = createSelector(getApp, app => app?.id ?? null);
export const getAppName = createSelector(getApp, app => app?.name ?? null);
export const getAppForms = createSelector(getApp, app => app?.forms ?? []);

export const getAppAttributes = createSelector(getApp, app => app?.attributes ?? []);
export const getAppAttributesForFilter = createSelector(getAppAttributes, attributes => attributes.filter(a => FilteringEngine.validFilterTypes.includes(a.type)));
export const getAppMediaTags = createSelector(getApp, () => []);
export const getAppFormsWithGeometry = createSelector(getAppForms, getAppAttributes, (forms, attributes) => {
  return forms.filter(f => attributes.findIndex(a => a.surveyId === f.id && a.questionType === 'geometry') !== -1);
});
export const getAppMediaItems = createSelector(getAppMediaTags, tags => {
  const items = [];
  for (const tag of tags) {
    items.push(...tag.items)
  }
  return items;
});
export const getAppIcon = createSelector(getApp, app => app?.icon ?? null);

const globe = polygon([[
  [-180, -90],
  [-180, 90],
  [180, 90],
  [180, -90],
  [-180, -90]
]]);

export const getAppForm = id => createSelector(getAppForms, forms => forms.find(f => f.id === id));
export const getAppAttributesForForm = id => createSelector(getAppAttributes, attributes => filterAttributesForForm(id, attributes));
export const getAppAssociationAttributesForForm = id => createSelector(getAppAttributes, attributes => attributes.filter(a => a.associatedSurveyId === id));
export const getAppAttributesForDisplay = id => createSelector(getAppAttributes, attributes => filterAttributesForDisplay(id, attributes));
export const getAppAttributesForCollection = id => createSelector(getAppAttributes, attributes => attributes.filter(a => a.parentCollectionId === id));
export const getAppCollections = createSelector(getApp, app => app?.collections ?? []);
export const getAppGeometricCollections = createSelector(getAppCollections, collections => collections.filter(c => c.geometric));
export const getAppGeometricCollectionsForMap = createSelector(getAppCollections, collections => collections.filter(c => c.geometric && c.mapLayer));
export const getAppCollection = id => createSelector(getAppCollections, collections => collections.find(c => c.id === id));
export const getAppBounds = createSelector(getApp, app => app?.bounds ?? null);
export const getAppBoundsBoundingBox = createSelector(getAppBounds, bounds => {
  if (!bounds || !booleanWithin({
    type: 'Polygon',
    coordinates: bounds.coordinates[0]
  }, globe)) {
    return null;
  }
  return bbox(bounds);
});
export const getAppFeatures = createSelector(getApp, app => app?.features ?? {});
export const getAppFeatureIsEnabled = (f: keyof CoreoProjectFeatures) => createSelector(getAppFeatures, features => !!features[f]);

// export const getAppMaps = createSelector(getApp, app => app?.maps ?? []);
// export const getAppMap = id => createSelector(getAppMaps, maps => maps.find(m => m.id === id));
export const getAppMapLayers = createSelector(getApp, app => app?.maps ?? []);
export const getAppMapLayer = id => createSelector(getAppMapLayers, layers => layers.find(l => l.id === id));
export const getAppPages = createSelector(getApp, app => {
  if (app && app.pages && app.pages.length) {
    return [...app.pages].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  } else {
    return [];
  }
});
export const getAppPagesForMenu = createSelector(getAppPages, pages => pages.filter(p => {
  if (!p.listed || p.type === 'custom' || p.type === 'records') {
    return false;
  }
  if (p.type === 'wysiwyg') {
    return p.blocks?.length > 0 || p.config?.blocks?.length > 0;
  }

  return true;
}));
export const getAppPage = id => createSelector(getAppPages, pages => pages.find(p => p.id === id));
export const getAppStates = createSelector(getApp, app => app?.states ?? []);
export const getAppDefaultState = createSelector(getAppStates, states => states.find(s => s.default));
export const getApps = (state: AppState): AppStatus[] => state.apps;
export const getAvailableApps = createSelector(getApps, apps => apps.filter(app => app.status === AppInstallStatus.READY));
export const getAppsCount = createSelector(getApps, apps => apps.length);
export const getAppWelcomePageId = createSelector(getApp, app => app && app.welcomePageId);
// export const getAppFreeTrialExpired = createSelector(getApp, app => app && app.freeTrialExpired);
export const getAppStatus = (id: number) => createSelector(getApps, apps => apps.find(a => a.id === id));
export const getAppIsInstalled = (id: number) => createSelector(getApps, apps => apps.map(a => a.id).includes(id));
export const getAppMediaDownloadState = (id: number) => createSelector(getApps, apps => {
  const a = apps?.find(app => app.id === id);
  return a?.mediaDownloadState;
});
export const getAppAttachmentDownloadState = (id: number) => createSelector(getApps, apps => {
  const a = apps?.find(app => app.id === id);
  return a?.attachmentDownloadState;
});
export const getCurrentAppStatus = createSelector(getApps, getAppId, (apps, id) => apps.find(a => a.id === id));
export const getCurrentAppWelcomeLastSeen = createSelector(getCurrentAppStatus, appStatus => appStatus && appStatus.welcomeLastSeen);
export const getCurrentAppLastSync = createSelector(getCurrentAppStatus, appStatus => appStatus && appStatus.lastSync);
export const getCurrentAppSyncState = createSelector(getCurrentAppStatus, appStatus => appStatus && appStatus.syncState);
export const getAppOfflineMigrationComplete = (state: AppState) => state.offlineMigrationComplete;

export const getAppTutorialShown = (state: AppState): boolean => state.tutorialShown;


export const getAppLastSync = (id: number) => createSelector(getApps, apps => apps.filter(a => a.id === id)[0]?.lastSync);

export const getAppHasGeometry = createSelector(getAppAttributes, attributes => {
  return attributes.some(a => a.questionType === 'geometry');
});

export const getAppTitleAttributeForForm = id => createSelector(getAppForm(id), getAppAttributesForForm(id), (form, attributes) => {
  return attributes.find(a => a.id === form.titleAttributeId);
});

export const getAppTitleAttributes = createSelector(getAppForms, getAppAttributes, (forms, attributes) => {
  const result = {};
  for (const form of forms.filter(f => f.titleAttributeId)) {
    result[form.id] = attributes.find(attribute => attribute.id === form.titleAttributeId);
  }
  return result;
});

export const getAppDescriptionAttributeForForm = id => createSelector(getAppForm(id), getAppAttributesForForm(id), (form, attributes) => {
  return attributes.find(a => a.id === form.secondaryTitleAttributeId);
});

export const getAppDescriptionAttributes = createSelector(getAppForms, getAppAttributes, (forms, attributes) => {
  const result = {};
  for (const form of forms.filter(f => f.titleAttributeId)) {
    const attribute = attributes.find(a => a.id === form.secondaryTitleAttributeId);
    if (typeof attribute !== 'undefined') {
      result[form.id] = attribute;
    }
  }
  return result;
});

export const getAppTitleForRecord = (record: CoreoRecord) => createSelector(getAppTitleAttributes, async titleAttributes => {
  const titleAttribute = titleAttributes[record.formId ?? record.surveyId];
  return await displayValue(record, titleAttribute) ?? `Record ${record.id}`;
});

export const getAppDescriptionForRecord = (record: CoreoRecord, label = false) => createSelector(getAppDescriptionAttributes, async descriptionAttributes => {
  const descriptionAttribute = descriptionAttributes[record.formId ?? record.surveyId];
  return `${label && descriptionAttribute ? (descriptionAttribute.label + ': ') : ''}${await displayValue(record, descriptionAttribute) ?? ''}`;
});

export const getAppAttachmentsShouldDownload = createSelector(getAppNetworkConnectionType, getAppSettings, (networkConnectionType, settings) => {
  switch (settings.syncAttachments) {
    case 'none':
    case 'unknown': {
      return false;
    }
    case 'cellular': {
      return networkConnectionType === 'cellular' || networkConnectionType === 'wifi';
    }
    case 'wifi': {
      return networkConnectionType === 'wifi';
    }
    default: {
      return false;
    }
  }
});

export const getAppAttachmentsShouldDownloadForApp = (id: number) => createSelector(getAppStatus(id), getAppAttachmentsShouldDownload, (appStatus, shouldDownload) => {
  return shouldDownload && appStatus.attachmentDownloadState.state === MediaDownloadStatus.DOWNLOADING;
});

export const getAppShouldHideUsernames = createSelector(getApp, app => app.hideUsernames);
