import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoMediaItem } from "../../../types";
import { SyncMediaItem } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseMediaItem {
  id: number;
  projectId: number;
  url: string;
  name: string;
  caption: string;
  type: string;
  sort: number;
  itemId: number;
  size: number;
}

export class AppDatabaseMediaItemRepo extends AppDatabaseRepo<AppDatabaseMediaItem, SyncMediaItem, CoreoMediaItem> {
  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['url', 'TEXT NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['caption', 'TEXT'],
    ['type', 'TEXT'],
    ['sort', 'INTEGER'],
    ['itemId', 'INTEGER'],
    ['pageId', 'INTEGER'],
    ['size', 'INTEGER']
  ]
  references = [
  ];
  indexes = [];
  tableName = 'mediaItems';
  primaryKey = ['id', 'projectId'];
  order = 'sort';

  public toInsertSet(mediaItem: SyncMediaItem): capSQLiteSet[] {
    const statement = `INSERT OR REPLACE INTO mediaItems
    (id,projectId,itemId,pageId,url,name,caption,type,sort,size)
    VALUES
    (?,?,?,?,?,?,?,?,?,?);`;
    return [{
      statement,
      values: [
        mediaItem.id,
        mediaItem.projectId,
        mediaItem.itemId,
        mediaItem.pageId,
        mediaItem.url,
        mediaItem.name,
        mediaItem.caption,
        mediaItem.type,
        mediaItem.sort,
        mediaItem.size
      ]
    }];
  }
  async deserialise(entity: AppDatabaseMediaItem): Promise<CoreoMediaItem> {
    return entity;
  }


}
