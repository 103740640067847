import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { modalController } from '@ionic/core';
import mime from 'mime-types';

import { CoreoRecordAttachment } from '../types';
import { CoreoAPI } from './api.service';

import { presignedUrl } from './coreo/coreo.queries';
import { readFileAsURL } from './db/filesystem.service';

const getExtension = (mimeType: string) => {
  // mime.extension returns false for 'image/jpg' so we have to do this extra check
  // also make sure we return the 3 character extension to support windows users
  if (mimeType === 'image/jpg' || mimeType === 'image/jpeg') {
    return 'jpg';
  }
  return mime.extension(mimeType);
}

export const uploadAttachment = async (projectId: number, attachment: CoreoRecordAttachment, signal: AbortSignal): Promise<string> => {
  const extension = getExtension(attachment.mimeType);
  const fileName = new Date().valueOf() + (extension ? `.${extension}` : '');

  const presignedUrlResponse = await CoreoAPI.instance.graphql(presignedUrl, {
    projectId,
    fileName,
    contentType: attachment.mimeType
  }, signal);

  const url = presignedUrlResponse.data.url;

  let body;
  if (Capacitor.isNativePlatform()) {
    const url = await readFileAsURL(attachment.fileLocation);
    body = await (await fetch(Capacitor.convertFileSrc(url))).blob();
  } else {
    const file = await Filesystem.readFile({
      path: attachment.fileLocation,
      directory: Directory.Data
    });
    body = file.data;
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': attachment.mimeType
    },
    body,
    signal
  });

  if (!response.ok) {
    throw new Error('Attachment upload failed');
  }

  return url.split('?')[0];
}


export const presentRecordModal = async (id: number, props = {}): Promise<HTMLIonModalElement> => {
  const modal = await modalController.create({
    component: 'app-record',
    componentProps: {
      recordId: id,
      ...props
    }
  });
  modal.present();
  return modal;
}
