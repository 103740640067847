import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoAppConfig, CoreoProjectRole } from "../../../types";
import { SyncProject } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseProject {
  id: number;
  name: string;
  description: string;
  icon: string;
  css: string;
  welcomePageId: number;
  slug: string;
  bounds: string;
  deleted: boolean;
  membership: CoreoProjectRole;
  lastUpdatedAt: number;
  organisationName: string;
  features: string;
  hideUsernames: boolean;
};

export class AppDatabaseProjectRepo extends AppDatabaseRepo<AppDatabaseProject, SyncProject, CoreoAppConfig> {
  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['description', 'TEXT'],
    ['icon', 'TEXT'],
    ['css', 'TEXT'],
    ['slug', 'TEXT'],
    ['welcomePageId', 'INTEGER'],
    ['bounds', 'TEXT'],
    ['deleted', 'INTEGER'],
    ['membership', 'TEXT'],
    ['lastUpdatedAt', 'INTEGER'],
    ['organisationName', 'TEXT'],
    ['features', 'TEXT'],
    ['lastRecordId', 'INTEGER DEFAULT 0'],
    ['lastRecordTimestamp', 'INTEGER DEFAULT 0'],
    ['rev', 'INTEGER DEFAULT 0'],
    ['hideUsernames', 'INTEGER DEFAULT 0'],
  ];
  primaryKey = ['id'];
  references = [];
  indexes = [];

  tableName: string = 'projects';

  public toInsertSet(project: SyncProject): capSQLiteSet[] {
    return [{
      statement: 'INSERT OR REPLACE INTO projects (id,name,description,slug,icon,css,welcomePageId,bounds,deleted,membership,lastUpdatedAt,organisationName,features,rev,hideUsernames,lastRecordId,lastRecordTimestamp) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,(SELECT lastRecordId FROM projects WHERE id = ?),(SELECT lastRecordTimestamp FROM projects WHERE id = ?))',
      values: [
        project.id,
        project.name,
        project.description,
        project.slug,
        project.icon,
        project.css,
        project.welcomePageId,
        JSON.stringify(project.bounds),
        project.deleted,
        project.membership,
        new Date().valueOf(),
        project.organisationName,
        JSON.stringify(project.features),
        project.rev, // revision
        project.hideUsernames,
        // Note these 2 IDS must be on the end of the array
        project.id,
        project.id,
      ]
    }];
  }

  public toDeleteSet(id: number): capSQLiteSet[] {
    return [{
      statement: 'DELETE FROM projects WHERE id = ?',
      values: [id]
    }];
  }

  async deserialise(entity: AppDatabaseProject): Promise<CoreoAppConfig> {
    return {
      ...entity,
      bounds: entity.bounds && JSON.parse(entity.bounds),
      features: entity.features && JSON.parse(entity.features)
    };
  }


}

