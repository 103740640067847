import { CoreoRecordCustomFilter, CoreoRecordCustomFilterOperator, CoreoRecordFilter } from '../types';

export class FilteringEngine {

  public static instance: FilteringEngine = new FilteringEngine();

  private constructor() { }

  static validFilterTypes = ['number', 'select', 'multiselect', 'text', 'integer', 'float', 'media', 'boolean', 'date', 'datetime'];

  getOperators(type: string): CoreoRecordCustomFilterOperator[] {
    switch (type) {
      case 'number': {
        return [];
      }
      case 'multiselect': {
        return [
          { id: 'and', label: 'Includes all', input: 'select', unary: false, multiple: true },
          { id: 'not:and', label: 'Does not include all', input: 'select', unary: false, multiple: true },
          { id: 'or', label: 'Includes any', input: 'select', unary: false, multiple: true },
          { id: 'not:or', label: 'Does not include any', input: 'select', unary: false, multiple: true },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false }
        ];
      }
      case 'select': {
        return [
          { id: 'eq', label: 'Is', input: 'select', unary: false, multiple: false },
          { id: 'ne', label: 'Is not', input: 'select', unary: false, multiple: false },
          { id: 'in', label: 'One of', input: 'select', unary: false, multiple: true },
          { id: 'notIn', label: 'Not one of', input: 'select', unary: false, multiple: true },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false }
        ];
      }
      case 'text': {
        return [
          { id: 'substring', label: 'Contains', input: 'text', unary: false, multiple: false },
          { id: 'eq', label: 'Is', input: 'text', unary: false, multiple: false },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false },
          { id: 'startsWith', label: 'Starts with', input: 'text', unary: false, multiple: false },
          { id: 'endsWith', label: 'Ends with', input: 'text', unary: false, multiple: false },
          { id: 'ne', label: 'Does not equal', input: 'text', unary: false, multiple: false }
        ];
      }
      case 'integer':
      case 'float': {
        return [
          { id: 'eq', label: 'Equals', input: 'number', unary: false, multiple: false },
          { id: 'gt', label: 'Greater than', input: 'number', unary: false, multiple: false },
          { id: 'gte', label: 'Greater than or equal to', input: 'number', unary: false, multiple: false },
          { id: 'lt', label: 'Less than', input: 'number', unary: false, multiple: false },
          { id: 'lte', label: 'Less than or equal to', input: 'number', unary: false, multiple: false },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false }
        ];
      }
      case 'media': {
        return [
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false }
        ];
      }
      case 'boolean': {
        return [
          { id: 'is:true', label: 'True', input: null, unary: true, multiple: false },
          { id: 'is:false', label: 'False', input: null, unary: true, multiple: false },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false }
        ];
      }
      case 'date':
      case 'datetime': {
        return [
          { id: 'lt', label: 'Before', input: 'date', unary: false, multiple: false },
          { id: 'gt', label: 'After', input: 'date', unary: false, multiple: false },
          { id: 'is:null', label: 'Is blank', input: null, unary: true, multiple: false },
          { id: 'not:null', label: 'Is not blank', input: null, unary: true, multiple: false }
        ];
      }
      default: {
        console.warn('Unknown type when building operators', type);
        return [];
      }
    }
  }

  calculateFilterCount(filter: CoreoRecordFilter) {
    let total = 0;

    total += filter.userId !== null ? 1 : 0;
    total += filter.states.length > 0 ? 1 : 0;
    total += filter.forms.length > 0 ? 1 : 0;
    total += filter.from !== null ? 1 : 0;
    total += filter.to !== null ? 1 : 0;
    total += filter.customFilters.filter(c => this.customFilterIsValid(c)).length;

    return total;
  }

  customFilterIsValid(customFilter: CoreoRecordCustomFilter) {
    return customFilter.formId !== null
      && customFilter.attribute !== null
      && customFilter.operator !== null
      && customFilter.operator.unary ? true : customFilter.value !== null;
  };

}

export default FilteringEngine;
