export interface AppConfig {
  NAME: string;
  COREO_API_URL: string;
  COREO_MAPS_URL: string;
  COREO_ADMIN_URL: string;
  MAPBOX_TOKEN: string;
  BING_TOKEN: string;
  SENTRY_DSN: string;
  LOCAL_DATABASE_NAME: string;
  IS_DEV: boolean;
  IMAGES_PROXY_ENABLED: boolean;
  IMAGES_CDN_URL: string;
}

export const CONFIGS: { [env: string]: AppConfig } = {
  local: {
    NAME: 'local',
    COREO_API_URL: 'http://localhost:3000',
    COREO_MAPS_URL: 'http://localhost:9000',
    COREO_ADMIN_URL: 'https://admin.dev.coreo.io',
    MAPBOX_TOKEN: 'pk.eyJ1IjoibmF0YXBwIiwiYSI6ImNrcG84a252eDAxbTYyd3I0NjB4cDI3eTEifQ.sSsuaecgIB7ukPeWSS1_sQ',
    SENTRY_DSN: 'https://e625cb3e9eec46f3a2931441ad0eb2cb@o416754.ingest.sentry.io/5354545',
    BING_TOKEN: 'AlWr5u4AGzUgzhE-m2d3Zbpx0uVOu1M4c2Lfv1ACN7LM998xxx_35IKMlef7PC0A',
    IS_DEV: true,
    LOCAL_DATABASE_NAME: 'COREO_LOCAL',
    IMAGES_PROXY_ENABLED: false,
    IMAGES_CDN_URL: 'https://coreo.twic.pics',
  },
  dev: {
    NAME: 'dev',
    COREO_API_URL: 'https://api.dev.coreo.io',
    COREO_MAPS_URL: 'https://maps.dev.coreo.io',
    COREO_ADMIN_URL: 'https://admin.dev.coreo.io',
    MAPBOX_TOKEN: 'pk.eyJ1IjoibmF0YXBwIiwiYSI6ImNrcG84a252eDAxbTYyd3I0NjB4cDI3eTEifQ.sSsuaecgIB7ukPeWSS1_sQ',
    SENTRY_DSN: 'https://e625cb3e9eec46f3a2931441ad0eb2cb@o416754.ingest.sentry.io/5354545',
    BING_TOKEN: 'AlWr5u4AGzUgzhE-m2d3Zbpx0uVOu1M4c2Lfv1ACN7LM998xxx_35IKMlef7PC0A',
    IS_DEV: true,
    LOCAL_DATABASE_NAME: 'COREO_DEV',
    IMAGES_PROXY_ENABLED: false,
    IMAGES_CDN_URL: 'https://coreo.twic.pics',
  },
  prod: {
    NAME: 'prod',
    COREO_API_URL: 'https://api.coreo.io',
    COREO_MAPS_URL: 'https://maps.coreo.io',
    COREO_ADMIN_URL: 'https://admin.coreo.io',
    MAPBOX_TOKEN: 'pk.eyJ1IjoibmF0YXBwIiwiYSI6ImNrcG84a252eDAxbTYyd3I0NjB4cDI3eTEifQ.sSsuaecgIB7ukPeWSS1_sQ',
    SENTRY_DSN: 'https://e625cb3e9eec46f3a2931441ad0eb2cb@o416754.ingest.sentry.io/5354545',
    BING_TOKEN: 'AlWr5u4AGzUgzhE-m2d3Zbpx0uVOu1M4c2Lfv1ACN7LM998xxx_35IKMlef7PC0A',
    IS_DEV: false,
    LOCAL_DATABASE_NAME: 'COREO_PROD',
    IMAGES_PROXY_ENABLED: false,
    IMAGES_CDN_URL: 'https://coreo.twic.pics',
  }
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAc4FMIydsCUJ2i6pkisdCkPU6kHv8KCC0',
  authDomain: 'coreo-1224.firebaseapp.com',
  databaseURL: 'https://coreo-1224.firebaseio.com',
  projectId: 'coreo-1224',
  storageBucket: 'coreo-1224.appspot.com',
  messagingSenderId: '621533577760',
  appId: '1:621533577760:web:834799b0a7bdaeca8db245',
  measurementId: 'G-H937XK1M95'
};
