import { CoreoAttributeQuestionType, CoreoAttributeType, CoreoCollectionSortMode, CoreoProjectFeatures, CoreoProjectRole, CoreoRecord } from "../../types";
import { CoreoAPI } from "../api.service";
import { SyncMapLayer } from "./models/map-layer.db";
import { SyncPage } from "./models/page.db";

export interface SyncUser {
  displayName: string;
  id: number;
  projectId: number;
  imageUrl: string;
  role: string;
  timestamp: number;
  username: string;
};

export interface SyncRecord extends Omit<CoreoRecord, 'timestamp'> {
  sourceAssociations: { sourceId: number; targetId: number; attributeId: number }[];
  timestamp: number;
};

export interface SyncMediaItem {
  id: number;
  projectId: number;
  itemId: number;
  pageId: number;
  url: string;
  name: string;
  caption: string;
  type: string;
  sort: number;
  size: number;
}
export interface SyncItem {
  id: number;
  key: string;
  value: string;
  data: string;
  collectionId: number;
  createdAt: string;
  updatedAt: string;
  geometry: string;
  sort: number;
  timestamp: number;
  mediaItems: SyncMediaItem[];
  projectId: number;
}

export interface SyncAttribute {
  id: number;
  projectId: number;
  uuid: string;
  order: number;
  visible: boolean;
  label: string;
  path: string;
  type: CoreoAttributeType;
  required: boolean;
  filterable: boolean;
  collectionId: number;
  parentCollectionId: number;
  associatedSurveyId: number;
  surveyId: number;
  meta: string;
  questionType: CoreoAttributeQuestionType;
  sectionIdx: number;
  config: string;
  conditions: any;
  text: string;
  description: string;
  help: string;
}

export interface SyncForm {
  id: number;
  projectId: number;
  name: string;
  title: string;
  color: string;
  anonymous: boolean;
  allowMemberUpdate: boolean;
  private: boolean;
  isPrimary: boolean;
  titleAttributeId: number;
  secondaryTitleAttributeId: number;
  visible: boolean;
  thankyou: string;
  sort: number;
  slug: string;
  style: any;
  mapSort: number;
  mapVisible: boolean;
}

export interface SyncCollection {
  id: number;
  projectId: number;
  name: string;
  sortAttributeId: number;
  sortMode: CoreoCollectionSortMode;
  geometric: boolean;
  style: any;
  mapLayer: boolean;
  mapSort: number;
  mapVisible: boolean;
};

export interface SyncState {
  projectId: number;
  color: string;
  default: boolean;
  name: string;
  sort: number;
  stateId: number;
  verified: boolean;
}

export interface SyncProject {
  id: number;
  name: string;
  description: string;
  slug: string;
  icon: string;
  css: string;
  welcomePageId: number;
  bounds: any;
  deleted: boolean;
  attributes: SyncAttribute[];
  surveys: SyncForm[];
  collections: SyncCollection[];
  pages: SyncPage[];
  states: SyncState[];
  maps: SyncMapLayer[];
  freeTrialExpired: boolean;
  organisationName: string;
  membership: CoreoProjectRole;
  features: CoreoProjectFeatures;
  rev: number;
  hideUsernames: boolean;
}

// // TODO put these in config? Or even in-app config?
export const SYNC_REQUEST_BATCH_SIZE = 2000;
export const SYNC_WRITE_SQL_BUFFER_SIZE = 10;

interface AppSyncResourceResponse {
  id: number;
  timestamp: number;
}
export class AppSyncResource<T extends AppSyncResourceResponse> implements AsyncIterable<any>, AsyncIterator<any> {

  private done = false;

  constructor(
    private appId: number,
    private resource: string,
    private lastId: number,
    private lastUpdatedAt: number,
    private batchSize: number,
    private force: boolean,
    private signal?: AbortSignal) { }

  isDone() {
    return this.done;
  }

  [Symbol.asyncIterator](): AsyncIterator<{ remaining: number; result: T[] }> {
    return this;
  }

  async next(): Promise<IteratorResult<{ remaining: number; result: T[] }>> {
    if (this.done) {
      return {
        done: this.done,
        value: []
      }
    }

    const url = `/sync/${this.appId}/${this.resource}?limit=${this.batchSize}&lastUpdatedAt=${this.lastUpdatedAt}&lastId=${this.lastId}${this.force ? '&initialSync=true' : ''}`;
    const response = await CoreoAPI.instance.get(url, {
      authentication: true,
      signal: this.signal
    });
    const result: { remaining: number; result: T[] } = await response.json();

    if (result.result.length === 0) {
      this.done = true;
    } else {
      if (result.result.length < this.batchSize) {
        this.done = true;
      } else {
        const lastRecord = result.result[result.result.length - 1];
        this.lastId = lastRecord.id;
        this.lastUpdatedAt = lastRecord.timestamp;
      }
    }

    return {
      done: result.result.length === 0,
      value: result
    };
  }

}
