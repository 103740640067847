import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';

import { generateRandomString } from '../utils.service';

import write_blob from 'capacitor-blob-writer';
import { CoreoRecordAttachment } from '../../types';
import { imageToImageProxy } from '../image.service';

export const clearDb = async () => {
  console.log('CLEARING DB TODO!!');
};

type FilePathComponent = string | number;
export const projectFilePath = (id: number, ...path: FilePathComponent[]) => `projects/${id}${path && path.length > 0 ? ('/' + path.join('/')) : ''}`;

export const uninstallProject = (id: number): Promise<void> => {
  return deleteProjectDir(id);
};

const mediaCache: Map<number, string> = new Map<number, string>();

/******************************************************************************
 * PROJECT FILE UTILS
******************************************************************************/
export const projectFileExists = async (id: number, ...path: FilePathComponent[]): Promise<boolean> => {
  try {
    await Filesystem.stat({
      path: projectFilePath(id, ...path),
      directory: Directory.Data
    });
    return true;
  } catch (e) {
    return false;
  }
}

export const createProjectDir = async (id: number, path: string = 'media'): Promise<void> => {
  try {
    await Filesystem.mkdir({
      path: projectFilePath(id, path),
      recursive: true,
      directory: Directory.Data
    });
  } catch (_e) {
  }
}

const deleteProjectDir = async (id: number): Promise<void> => {
  const dir = projectFilePath(id);
  try {
    await Filesystem.rmdir({
      path: dir,
      recursive: true,
      directory: Directory.Data
    });
  } catch (e) {
    console.warn(e);
  }
}

export const writeProjectFile = async (id: number, data: Blob, ...pathComponents: FilePathComponent[]): Promise<string> => {
  const path = projectFilePath(id, ...pathComponents);
  await write_blob({
    path,
    directory: Directory.Data,
    blob: data,
    fast_mode: true
  });
  return path;
}

const readProjectDir = async (id: number, ...path: FilePathComponent[]): Promise<string[]> => {
  try {
    const result = await Filesystem.readdir({
      path: projectFilePath(id, ...path),
      directory: Directory.Data
    });
    return result.files.map(s => s.name);
  } catch (e) {
    return [];
  }
}

export const readFileAsURL = async (path): Promise<string> => {
  try {
    if (!Capacitor.isNativePlatform()) {
      const webResult = await Filesystem.readFile({
        directory: Directory.Data,
        path,
      });
      const url = URL.createObjectURL(webResult.data as any);
      return url;
    }

    const result = await Filesystem.getUri({
      directory: Directory.Data,
      path
    });
    return Capacitor.convertFileSrc(result.uri);
  } catch (e) {
    console.warn('File not found?', e, path);
    return null;
  }
}

const readProjectFileAsURL = async (id: number, ...path: FilePathComponent[]): Promise<string> => {
  return readFileAsURL(projectFilePath(id, ...path));
}

export const getProjectIcon = async (id: number): Promise<string> => {
  const iconPath = 'icon.png';
  const exists = await projectFileExists(id, iconPath);
  return exists ? readProjectFileAsURL(id, iconPath) : null;
}

export const getProjectMediaURL = async (projectId: number, mediaItemId: number): Promise<string> => {
  if (mediaCache.has(mediaItemId)) {
    return mediaCache.get(mediaItemId);
  }

  const exists = await projectFileExists(projectId, 'media', mediaItemId);
  if (exists) {
    const url = await readProjectFileAsURL(projectId, 'media', mediaItemId);
    mediaCache.set(mediaItemId, url);
    return url;
  } else {
    console.debug('Project media not found', mediaItemId);
    return null;
  }
}

export const getCurrentProjectMediaURL = async (appId: number, mediaItemId: number): Promise<string> => {
  return getProjectMediaURL(appId, mediaItemId);
}

// Takes file from cache and stores it in the top level of app storage
// note that it cleans up the original file
export const persistProjectFile = async (id: number, URI: string): Promise<string> => {
  const isNative = Capacitor.isNativePlatform();
  const filename = `${generateRandomString()}`;
  const path = projectFilePath(id, 'tmp', filename);

  if (!isNative) {
    const dataBlob = await (await fetch(URI)).blob();
    await writeProjectFile(id, dataBlob, 'tmp', filename);
  } else {
    await Filesystem.copy({
      from: URI,
      to: path,
      toDirectory: Directory.Data
    });
    await Filesystem.deleteFile({
      path: URI
    });
  }

  return path;
}

// const CACHE_VERSION = 1;
// const CACHE = `coreo-media-cache-${CACHE_VERSION}`;

export interface ConsolidateMediaItem {
  id: number;
  url: string;
};

export const consolidateMedia = async<T extends ConsolidateMediaItem>(projectId: number, folder: string, items: T[]): Promise<T[]> => {
  const currentStoredMedia = await readProjectDir(projectId, folder);
  return items.filter(c => !currentStoredMedia.includes(c.id.toString()));
}

export const convertBlobToBase64 = (blob: Blob): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const initProjectFilesystem = async (id: number) => {
  try {
    await Promise.all([
      Filesystem.mkdir({
        path: projectFilePath(id, 'tmp'),
      recursive: true,
      directory: Directory.Data
      }),
      Filesystem.mkdir({
      path: projectFilePath(id, 'media'),
      recursive: true,
      directory: Directory.Data
      }),
      Filesystem.mkdir({
        path: projectFilePath(id, 'attachments'),
        recursive: true,
        directory: Directory.Data
      }),
      Filesystem.mkdir({
        path: projectFilePath(id, 'maps'),
        recursive: true,
        directory: Directory.Data
      })
    ]);
  } catch (_e) {
  }
}

export const storeProjectIcon = async (id: number, icon: string) => {
  if (!icon) {
    return;
  }
  icon = imageToImageProxy(icon);

  let response: Response;

  try {
    response = await fetch(icon);
  } catch (error) {
    return;
  };

  const data = await response.blob();
  await writeProjectFile(id, data, 'icon.png');
};

export const resolveAttachmentUrl = async (attachment: CoreoRecordAttachment): Promise<string> => {
  if (!attachment) {
    return null;
  }
  const { url, fileLocation } = attachment;
  if (!(url || fileLocation)) {
    return null;
  }

  return fileLocation ? readFileAsURL(fileLocation) : (url ? imageToImageProxy(url) : null);
  // if(!Capacitor.isNativePlatform()){
  //   return url ? imageToImgix(url) : readFileAsURL(fileLocation);
  // }

  // if(fileLocation){
  //   return readFileAsURL(fileLocation);
  // }

  // if (url.startsWith('http')) {
  //   return imageToImgix(url);
  // } else {
  //   return readFileAsURL(url);
  // }
}
