import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoAppState } from "../../../types";
import { SyncState } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseState {
  projectId: number;
  stateId: number;
  name: string;
  verified: boolean;
  default: boolean;
  sort: number;
  color: string;
}

export class AppDatabaseStateRepo extends AppDatabaseRepo<AppDatabaseState, SyncState, CoreoAppState> {

  columns = [
    ['projectId', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['verified', 'INTEGER'],
    ['stateId', 'INTEGER'],
    ['default', 'INTEGER'],
    ['sort', 'INTEGER'],
    ['color', 'TEXT']
  ]
  tableName: string = 'states';
  references = [];
  indexes = [];
  primaryKey = [];

  public toInsertSet(state: SyncState): capSQLiteSet[] {
    return [{
      statement: 'INSERT OR REPLACE INTO states (projectId,color,"default",name,sort,stateId,verified) VALUES (?,?,?,?,?,?,?)',
      values: [
        state.projectId,
        state.color,
        state.default,
        state.name,
        state.sort,
        state.stateId,
        state.verified
      ]
    }];
  }

  async deserialise(entity: AppDatabaseState): Promise<CoreoAppState> {
    return entity;
  }


}
