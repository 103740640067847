import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoMapLayer } from "../../../types";
import SQLDatabase from "../sql.service";
import { AppDatabaseRepo } from "./base.db";

export interface SyncMapLayer {
  id: number;
  projectId: number;
  mapId: number;
  name: string;
  sort: number;
  layout: any;
  paint: any;
  filter: any;
  sourceType: 'records' | 'collections' | 'image';
  sourceId: number;
  source: string;
  type: string;
  fileLocation: string;
  visible: boolean;
  style: any;
  bounds: number[];
}

export interface AppDatabaseMapLayer {
  id: number;
  projectId: number;
  name: string;
  sort: number;
  layout: string;
  paint: string;
  filter: string;
  sourceType: string;
  sourceId: number;
  source: string;
  type: string;
  fileLocation: string;
  visible: boolean;
  style: any;
  bounds: any;
}


export class AppDatabaseMapLayerRepo extends AppDatabaseRepo<AppDatabaseMapLayer, SyncMapLayer, CoreoMapLayer> {

  tableName: string = 'mapLayers';

  public toInsertSet(mapLayer: SyncMapLayer): capSQLiteSet[] {
    const statement = `INSERT OR REPLACE INTO ${this.tableName}
      (id,projectId,mapId,name,sort,layout,paint,filter,sourceType,sourceId,source,type,fileLocation,visible, style, bounds)
      VALUES
      (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?);`;
    return [{
      statement,
      values: [
        mapLayer.id,
        mapLayer.projectId,
        0,
        mapLayer.name,
        mapLayer.sort,
        JSON.stringify(mapLayer.layout),
        JSON.stringify(mapLayer.paint),
        JSON.stringify(mapLayer.filter),
        mapLayer.sourceType,
        mapLayer.sourceId,
        mapLayer.source,
        mapLayer.type,
        mapLayer.fileLocation ?? null,
        mapLayer.visible,
        JSON.stringify(mapLayer.style ?? {}),
        JSON.stringify(mapLayer.bounds ?? [])
      ]
    }];
  }

  async deserialise(entity: AppDatabaseMapLayer): Promise<CoreoMapLayer> {
    return {
      ...entity,
      sourceType: entity.sourceType as CoreoMapLayer['sourceType'],
      layout: entity.layout && JSON.parse(entity.layout),
      paint: entity.layout && JSON.parse(entity.paint),
      filter: entity.layout && JSON.parse(entity.filter),
      bounds: entity.bounds && JSON.parse(entity.bounds),
      style: entity.bounds && JSON.parse(entity.style),
    };
  }

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['mapId', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['sort', 'INTEGER'],
    ['layout', 'TEXT'],
    ['paint', 'TEXT'],
    ['filter', 'TEXT'],
    ['sourceType', 'TEXT'],
    ['sourceId', 'INTEGER'],
    ['source', 'TEXT'],
    ['type', 'TEXT'],
    ['fileLocation', 'TEXT'],
    ['visible', 'INTEGER'],
    ['style', 'TEXT'],
    ['bounds', 'TEXT']
  ];

  indexes = [];
  references = [];
  primaryKey = ["id", "projectId"];

  public setFileLocationSet(id: number, path: string): capSQLiteSet {
    return {
      statement: SQLDatabase.update().table(this.tableName).set('fileLocation', path).where('id = ?', id).toString(),
      values: []
    };
  }

}
