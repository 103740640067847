import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoUser } from "../../../types";
import { SyncUser } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

interface AppDatabaseUser {

}

export class AppDatabaseUserRepo extends AppDatabaseRepo<AppDatabaseUser, SyncUser, CoreoUser> {

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['role', 'TEXT NOT NULL'],
    ['displayName', 'TEXT NOT NULL'],
    ['username', 'TEXT NOT NULL'],
    ['imageUrl', 'TEXT'],
    ['timestamp', 'INTEGER NOT NULL'],
  ];

  tableName: string = 'users';
  references = [];
  indexes = [
    'CREATE INDEX IF NOT EXISTS users_projectId ON users(projectId)'
  ];
  primaryKey = ['id', 'projectId'];

  public toInsertSet(user: SyncUser): capSQLiteSet[] {
    const statement = `INSERT OR REPLACE INTO users
    (id,projectId,role,displayName,username,imageUrl,timestamp)
    VALUES
    (?,?,?,?,?,?,?)`;
    return [{
      statement,
      values: [
        user.id,
        user.projectId,
        user.role,
        user.displayName ?? '',
        user.username ?? '',
        user.imageUrl,
        user.timestamp
      ]
    }];
  }

  async deserialise(entity: AppDatabaseUser): Promise<CoreoUser> {
    // TODO this needs sorting out
    return entity as CoreoUser;
  }

}
