import { Directory, Encoding, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import Sentry from './sentry.service';

class CoreoStorage {

  // The legacy behaviour was to use the Cache directory, so we'll start by using this,
  // but the init() function will upgrade from this to use the Data directory.
  private directory = Directory.Cache;

  // UTF8 encoding for everything as we are just storing JSON strings.
  private readonly encoding = Encoding.UTF8;

  // Previous to version 1.3.2 of the app, we were storing in the Cache directory,
  // which is clearly wrong. The below will move these files into the Data directory
  async init() {

    try {
      // Get any persist files in the cache directory
      const cacheFiles = await Filesystem.readdir({
        directory: Directory.Cache,
        path: ''
      });
      const persistFiles = cacheFiles.files.filter(f => f.name.startsWith('persist:')).map(s => s.name);

      // Move any - note that copyFile() seems to mess with the encoding so
      // we do this read then write
      for (const p of persistFiles) {
        const result = await Filesystem.readFile({
          path: p,
          directory: Directory.Cache,
          encoding: this.encoding
        });
        await Filesystem.writeFile({
          path: p,
          data: result.data,
          directory: Directory.Data,
          encoding: this.encoding
        });
      }

      // If we found any, tidy up
      if (persistFiles.length > 0) {
        await Promise.all(persistFiles.map(p => Filesystem.deleteFile({
          path: p,
          directory: Directory.Cache
        })));
      }
      // Finally switch to using the Data directory
      this.directory = Directory.Data;
    } catch (e) {
      Sentry.captureException(e);
      console.warn(e);
    }
  }

  async getItem(key: string): Promise<string> {
    try {
      const result = await Filesystem.readFile({
        path: key,
        directory: this.directory,
        encoding: this.encoding
      });
      return result.data as string;
    } catch (e) {
      return null;
    }
  }

  async setItem(key: string, value: string): Promise<WriteFileResult> {
    return Filesystem.writeFile({
      path: key,
      data: value,
      directory: this.directory,
      encoding: this.encoding
    });
  }

  async removeItem(path: string): Promise<void> {
    return Filesystem.deleteFile({
      path,
      directory: this.directory
    });
  }
}

export default new CoreoStorage();
