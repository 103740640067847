import { h } from '@stencil/core';

import { CoreoAttribute, CoreoRecord } from '../types';

import AppDatabase from './db/app-db.service';
import { imageToImageProxy } from './image.service';
import { presentDate } from './utils.service';

export const displayMedia = (record: CoreoRecord, attribute: CoreoAttribute) => {

  const images = record.attachments.filter(a => a.attributeId === attribute.id).map(a => {
    if (a.mimeType.startsWith('image') || attribute?.questionType === 'photo') {
      return <img src={imageToImageProxy(a.url)} />
    }
  });
  return images.length > 0 && <div class="">{images}</div>;
}

export const displayValue = async (record: CoreoRecord, attribute: CoreoAttribute): Promise<string> => {

  if (attribute?.type === 'attachment' || attribute?.type === 'media') {
    return displayMedia(record, attribute);
  }

  const value: any = record?.data?.[attribute?.path];

  if (typeof value === 'undefined' || value === "" || value === null || !attribute) {
    return null;
  }

  switch (attribute?.type) {
    case 'integer': {
      const num = parseInt(value);
      return isNaN(num) ? '' : num.toString();
    }
    case 'float': {
      const float = parseFloat(value);
      return isNaN(float) ? '' : float.toString();
    }
    case 'boolean': {
      return (<div>{value === true ? 'Yes' : (value === false ? 'No' : '')}</div>);
    }

    case 'date':
    case 'datetime': {
      return presentDate(value, attribute.type === 'date' ? 'PPP' : 'PPpp');
    }
    case 'multiselect': {
      if (Array.isArray(value)) {
        const values = await Promise.all(value.map(async k => {
          const item = await AppDatabase.instance.getCollectionItem(attribute.collectionId, record.projectId, k);
          return item?.value ?? '';
        }));
        return values.join(', ');
      }
    }
    case 'select': {
      const item = await AppDatabase.instance.getCollectionItem(attribute.collectionId, record.projectId, value);
      return item?.value ?? '';
    }
    case 'coordinatetransform': {
      console.warn('COORDINATE');
      return value;
    }
    case 'coordinatetransform': {
      console.warn('COORDINATE');
      return value;
    }

    default: {
      return value;
    }
  }

}
