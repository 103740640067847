import { Geolocation, WatchPositionCallback } from '@capacitor/geolocation';
import { createStore } from '@stencil/store';
import { Position } from 'geojson';
import throttle from 'lodash.throttle';

export interface GeolocationStore {
  location: Position;
  accuracy: number;
  heading: number;
  headingPermission: boolean;
}

const store = createStore<GeolocationStore>({
  location: null,
  accuracy: null,
  heading: null,
  headingPermission: null
});

let callbackId: string = null;

const geolocationCallback: WatchPositionCallback = (position, err) => {
  if (err) {
    console.warn(err);
    return;
  }
  store.state.location = [position.coords.longitude, position.coords.latitude];
  store.state.accuracy = position.coords.accuracy;
};

const updateHeadingThrottled = throttle(heading => state.heading = heading, 20);

const deviceOrientationListener = (deviceOrientationevent: DeviceOrientationEvent) => {
  const heading = 'webkitCompassHeading' in deviceOrientationevent ? deviceOrientationevent['webkitCompassHeading'] : (deviceOrientationevent.alpha * -1);
  updateHeadingThrottled(heading);
  state.headingPermission = true;
};

// Adds a device orientation listener
const addDeviceOrientationListener = () => {
  const eventName = 'ondeviceorientationabsolute' in window ? 'deviceorientationabsolute' : 'deviceorientation';
  window.addEventListener(eventName, deviceOrientationListener, {
    passive: true
  })
};

export const startDeviceOrientation = () => {
  if (state.headingPermission === false) {
    return;
  }
  // tslint:disable-next-line: no-unbound-method
  if (state.headingPermission === null && typeof window.DeviceMotionEvent !== 'undefined' && typeof (window.DeviceMotionEvent as any).requestPermission === 'function') {
    // DeviceOrientationEvent.requestPermission().then(response => {
    //   if (response === 'granted') {
    //     state.headingPermission = true;
    //   }
    // }).catch(err => {
    //   console.error(err);
    //   state.headingPermission = false;
    // });
  } else {
    addDeviceOrientationListener();
    state.headingPermission = true;
  }
}
addDeviceOrientationListener();

export const geolocationStartTracking = async () => {
  if (callbackId) {
    return;
  }

  callbackId = await Geolocation.watchPosition({
    enableHighAccuracy: true,
    timeout: 1000,
    maximumAge: 0
  }, geolocationCallback);
}

export const state = store.state;
export const onChange = store.onChange;
export default state;
