import { format, parseISO } from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
import { difference, polygon } from '@turf/turf';
import { feature as featureHelper, MultiPolygon, Polygon } from '@turf/helpers';

export const humanFileSize = (bytes: number, si = true): string => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export const ionToCoreoDate = t => {
  const d = new Date(t);
  d.setUTCHours(0, 0, 0, 0);
  return d.toISOString();
}

export const ionToCoreoDatetime = t => {
  const d = new Date(t);
  return d.toISOString();
}

export const presentDate = (date: string, fmt = 'PPP'): string => {
  return format(parseISO(date), fmt, { locale: enGb })
}

export const debounce = (callback, time) => {
  let timer;

  return function () {
    clearTimeout(timer);

    const args = Array.prototype.slice.call(arguments);
    args.unshift(this);
    timer = setTimeout(callback.bind.apply(callback, args), time);
  };
};

export const asyncSome = async<T extends any>(arr: T[], predicate: (T) => Promise<boolean>) => {
  for (let e of arr) {
    if (await predicate(e)) {
      return true;
    }
  }
  return false;
}

export const asyncFilter = async<T extends any>(arr: T[], predicate: (T) => Promise<boolean>) => {
  return Promise.all(arr.map(a => predicate(a))).then(results => {
    return arr.filter((_v, index) => results[index]);
  });
};

// Prefer webworker where possible
export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) { // it's just the same object. No need to compare.
    return true;
  }

  if (obj1 === null || obj2 === null || typeof obj1 === 'undefined' || typeof obj2 === 'undefined') {
    return false;
  }

  if (isPrimitive(obj1) && isPrimitive(obj2)) { // compare primitives
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // compare objects with same number of keys
  for (const key in obj1) {
    if (!(key in obj2) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

const isPrimitive = obj => {
  return (obj !== Object(obj));
}

export const delay = (time: number): Promise<any> => {
  return new Promise<void>((resolve, _) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const generateRandomString = (stringLength = 10): string => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return Array.from({ length: stringLength }, () => {
    return possible[Math.floor(Math.random() * possible.length)];
  }).join('');
}

export const generateRandomNumber = (min: number, max: number = Number.MAX_SAFE_INTEGER): number => {
  return Math.floor(min + Math.random() * (max - min));
}

// https://gist.github.com/keeth/d5d94dab40b4d697618c075e155197da
export const isNetworkError = error =>
  /The Internet connection appears to be offline|Failed to fetch|Network request failed|NetworkError when attempting to fetch resource|The network connection was lost/.test(error.message)
  || error.statusCode === 502
  || error.statusCode === 504;

export const vh = v => {
  const h1 = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  return (v * h1) / 100;
};

export const vw = w => {
  const w1 = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  return (w * w1) / 100;
}

const globalLayer = polygon([[
  [-180, -90],
  [-180, 90],
  [180, 90],
  [180, -90],
  [-180, -90]
]]);

export const getGlobalMaskLayer = (bounds: MultiPolygon | Polygon) => {
  const appLayer = featureHelper(bounds);
  return difference(globalLayer, appLayer);
}

export const metersPerPixel = (latitude: number, zoomLevel: number): number => {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (
    (earthCircumference * Math.cos(latitudeRadians)) /
    Math.pow(2, zoomLevel + 8)
  );
};
