import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoRecordAttachment } from "../../../types";
import SQLDatabase from "../sql.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseAttachment {
  id: number;
  attributeId: number;
  mimeType: string;
  projectId: number;
  recordId: number;
  url: string;
  fileLocation: string;
  size: number;
}

export interface SyncAttachment {
  attributeId: number;
  mimeType: string;
  projectId: number;
  recordId: number;
  url: string;
  size: number;
  fileLocation: string;
}

export class AppDatabaseAttachmentRepo extends AppDatabaseRepo<AppDatabaseAttachment, SyncAttachment, CoreoRecordAttachment> {
  tableName: string = 'attachments';

  public toInsertSet(attachment: SyncAttachment): capSQLiteSet[] {
    const attachmentsStatement = `INSERT OR REPLACE INTO attachments
      (attributeId,url,fileLocation,mimeType,recordId,projectId,size)
      VALUES
      (?,?,?,?,?,?,?)`;
    return [{
      statement: attachmentsStatement,
      values: [
        attachment.attributeId,
        attachment.url,
        attachment.fileLocation,
        attachment.mimeType,
        attachment.recordId,
        attachment.projectId,
        attachment.size ?? 0
      ]
    }];
  }

  async deserialise(entity: AppDatabaseAttachment): Promise<any> {
    return entity;
  }

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['attributeId', 'INTEGER NOT NULL'],
    ['url', 'TEXT'],
    ['fileLocation', 'TEXT'],
    ['mimeType', 'TEXT NOT NULL'],
    ['recordId', 'INTEGER'],
    ['projectId', 'INTEGER NOT NULL'],
    ['size', 'INTEGER NOT NULL']
  ];

  primaryKey = ['id'];

  indexes = [
    // 'CREATE INDEX IF NOT EXISTS attachments_recordId ON attachments (recordId)',
    // 'CREATE INDEX IF NOT EXISTS attachments_projectId ON attachments (projectId)'
  ];
  references = [];

  public toDeleteSetForRecord(recordId: number): capSQLiteSet[] {
    return [{
      statement: `DELETE FROM ${this.tableName} WHERE "recordId" = ?`,
      values: [
        recordId
      ]
    }];
  }

  public getUnsynchronised(projectId: number) {
    return this.search(q => q.where('projectId = ?', projectId).where('fileLocation IS NULL')).toArray();
  }

  public setFileLocationSet(id: number, path: string): capSQLiteSet {
    return {
      statement: SQLDatabase.update().table(this.tableName).set('fileLocation', path).where('id = ?', id).toString(),
      values: []
    };
  }
}
