

import { GraphQLResponse } from './coreo/coreo.model';
export class CoreoAPI {

  private authToken: string;
  private apiUrl: string;

  private appVersion: string = '';
  private appBuild: string = '';

  // Singleton
  public static instance: CoreoAPI = new CoreoAPI();

  public setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  public setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  public setAppDetails(appVersion: string, appBuild: string) {
    this.appVersion = appVersion;
    this.appBuild = appBuild;
  }

  private constructor() { }

  async graphql<R = any, V = Record<string, any>>(query: string, variables: V, signal?: AbortSignal): Promise<GraphQLResponse<R>> {
    const q = query.replace(/[\n\r]*/g, '');
    const headers = this.authHeaders();
    headers['Content-Type'] = 'application/json';
    headers['X-Coreo-App-Version'] = this.appVersion;
    headers['X-Coreo-App-Build'] = this.appBuild;

    try {
      const response = await fetch(`${this.apiUrl}/graphql`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ query: q, variables }),
        signal
      });

      const result: GraphQLResponse = await response.json();

      if (result.errors) {
        console.error(result.errors);
      }

      // We allow the result to be returned as-is, with errors included, so that the consuming party can
      // decide how to react in its particular context
      return result;
    } catch (error) {
      // This would be an Network / HTTP error, so we can barf here, I tihnk
      throw error;
    }
  }

  async post(url: string, body: string | FormData, options: { authentication: boolean; signal: AbortSignal } = { authentication: true, signal: null }): Promise<Response> {
    const headers = options.authentication ? this.authHeaders() : {};

    headers['X-Coreo-App-Version'] = this.appVersion;
    headers['X-Coreo-App-Build'] = this.appBuild;

    if (!(body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    return fetch(`${this.apiUrl}${url}`, {
      method: 'POST',
      headers,
      body: body as FormData,
      signal: options.signal
    });
  }

  get(url: string, options: { authentication: boolean, signal: AbortSignal } = { authentication: true, signal: null }) {
    const headers = options.authentication ? this.authHeaders() : {};

    headers['X-Coreo-App-Version'] = this.appVersion;
    headers['X-Coreo-App-Build'] = this.appBuild;

    return fetch(`${this.apiUrl}${url}`, {
      method: 'GET',
      headers,
      signal: options.signal
    });
  }

  // Internal
  //

  private authHeaders(): HeadersInit {
    const headers = {};

    if (this.authToken) {
      headers['Authorization'] = `JWT ${this.authToken}`;
    }

    return headers;
  }
}
