export const collectionItemBoundingBox = `
  query CollectionItemBoundingBox($projectId: Int!, $collectionId: Int!, $itemId: Int!){
    project(id: $projectId) {
      collections(where: { id: $collectionId }) {
        items(where: { id: $itemId }) {
          boundingBox {
            type,
            coordinates
          }
        }
      }
    }
  }
`;

export const recordFieldsFragment = `
  fragment recordFields on Record{
    id
    data
    state
    geometry {
      type,
      coordinates
    }
    userId
    surveyId
    updatedAt: updatedAtPrecise
    createdAt
    deleted
    user {
      username
    }
  }
`;

export const presignedUrl = `
  query PresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!) {
    url: presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
  }
`;
