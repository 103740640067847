

//       id INTEGER PRIMARY KEY NOT NULL,
// projectId INTEGER NOT NULL,
// title TEXT,
// icon TEXT,
// config TEXT,
// type TEXT,
// updatedAt TEXT,
// listed INTEGER,
// blocks TEXT
import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoAppPage } from "../../../types";
import { SyncMediaItem } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseProjectPage {
  id: number;
  projectId: number;
  title: string;
  icon: string;
  config: string;
  type: string;
  updatedAt: string;
  listed: boolean;
  blocks: string;
  order: number;
}

export interface SyncPage {
  config: any;
  id: number;
  projectId: number;
  listed: boolean;
  title: string;
  type: string;
  icon: string;
  blocks: any[];
  updatedAt: string;
  mediaItems: SyncMediaItem[];
  order: number;
}

export class AppDatabasePageRepo extends AppDatabaseRepo<AppDatabaseProjectPage, SyncPage, CoreoAppPage> {
  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['title', 'TEXT'],
    ['icon', 'TEXT'],
    ['config', 'TEXT'],
    ['type', 'TEXT'],
    ['updatedAt', 'TEXT'],
    ['listed', 'INTEGER'],
    ['order', 'INTEGER'],
    ['blocks', 'TEX'],
  ];
  indexes = [];
  references = [];
  primaryKey = ["id", "projectId"];

  tableName: string = 'pages';

  public toInsertSet(page: SyncPage): capSQLiteSet[] {
    return [{
      statement: `INSERT OR REPLACE INTO ${this.tableName} (id,projectId,title,icon,config,type,updatedAt,listed,[order],blocks) VALUES (?,?,?,?,?,?,?,?,?,?)`,
      values: [
        page.id,
        page.projectId,
        page.title,
        page.icon,
        JSON.stringify(page.config),
        page.type,
        page.updatedAt,
        page.listed,
        page.order,
        JSON.stringify(page.blocks)
      ]
    }];
  }

  async deserialise(entity: AppDatabaseProjectPage): Promise<CoreoAppPage> {
    return {
      ...entity,
      config: JSON.parse(entity.config),
      blocks: JSON.parse(entity.blocks),
      mediaItems: []
    }
  }


}

