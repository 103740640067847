import { capSQLiteSet } from "@capacitor-community/sqlite";
import { CoreoCollection, CoreoCollectionSortMode } from "../../../types";
import { SyncCollection } from "../app-sync.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseCollection {
  id: number;
  projectId: number;
  name: string;
  sortAttributeId: number;
  sortMode: CoreoCollectionSortMode;
  geometric: boolean;
  style: string;
  mapLayer: boolean;
  mapSort: number;
  mapVisible: boolean;
};

export class AppDatabaseCollectionRepo extends AppDatabaseRepo<AppDatabaseCollection, SyncCollection, CoreoCollection> {
  tableName: string = 'collections';

  public toInsertSet(collection: SyncCollection): capSQLiteSet[] {
    return [{
      statement: 'INSERT OR REPLACE INTO collections (id,projectId,name,sortAttributeId,sortMode,geometric,style,mapLayer,mapSort,mapVisible) VALUES (?,?,?,?,?,?,?,?,?,?)',
      values: [
        collection.id,
        collection.projectId,
        collection.name,
        collection.sortAttributeId,
        collection.sortMode,
        collection.geometric,
        JSON.stringify(collection.style),
        collection.mapLayer,
        collection.mapSort,
        collection.mapVisible
      ]
    }];
  }

  async deserialise(collection: AppDatabaseCollection): Promise<CoreoCollection> {
    return {
      ...collection,
      style: JSON.parse(collection.style)
    };
  }

  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['name', 'TEXT NOT NULL'],
    ['boundingBox', 'TEXT'],
    ['sortAttributeId', 'INTEGER'],
    ['sortMode', 'TEXT'],
    ['geometric', 'INTEGER'],
    ['style', 'TEXT'],
    ['mapLayer', 'INTEGER'],
    ['mapSort', 'INTEGER'],
    ['mapVisible', 'INTEGER']
  ];
  indexes = [];
  references = [];
  primaryKey = ["id", "projectId"];


}
