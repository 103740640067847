import { capSQLiteSet } from "@capacitor-community/sqlite";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseAssociation {
  sourceId: number;
  targetId: number;
  attributeId: number;
  projectId: number;
}

export type SyncAssociation = [number, number, number, number];

export class AppDatabaseAssociationRepo extends AppDatabaseRepo<AppDatabaseAssociation, SyncAssociation, AppDatabaseAssociation> {

  columns = [
    ['sourceId', 'INTEGER NOT NULL'],
    ['targetId', 'INTEGER NOT NULL'],
    ['attributeId', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL']
  ];
  references = [
    'UNIQUE(sourceId,targetId,attributeId)'
  ];

  indexes = [
    // 'CREATE INDEX IF NOT EXISTS association_sourceId ON associations(sourceId)',
    // 'CREATE INDEX IF NOT EXISTS association_targetId ON associations(targetId)',
    // 'CREATE INDEX IF NOT EXISTS association_projectId ON associations(projectId)'
  ];
  primaryKey = [];
  tableName: string = 'associations';

  public toInsertSet(association: SyncAssociation): capSQLiteSet[] {
    const statement = `INSERT OR REPLACE INTO associations (sourceId,targetId,attributeId,projectId) VALUES (?,?,?,?);`
    return [{
      statement,
      values: association
    }];
  }

  public toDeleteSetForRecord(recordId: number): capSQLiteSet[] {
    return [{
      statement: `DELETE FROM ${this.tableName} WHERE "sourceId" = ?`,
      values: [
        recordId
      ]
    }];
  }

  async deserialise(entity: AppDatabaseAssociation): Promise<AppDatabaseAssociation> {
    return entity;
  }

}
